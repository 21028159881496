
export const services = [
    {
        title: "Technology Enthusiast",
        icon: 'https://csgeeek.github.io/web.png',
    },
    {
        title: "Software Engineer",
        icon: 'https://csgeeek.github.io/web.png',
    },
    {
        title: "Traveller",
        icon: 'https://csgeeek.github.io/web.png',
    },
];

export const name = 'Kaartik';

export const experiences = [
    {
        'company': 'Cimpress',
        'role': 'Senior Software Engineer',
        'duration': 'July 2023 - Present',
        'logo': 'https://tse3.mm.bing.net/th?id=OIP.FOyST5hIvuz1QmKJ1SgLEgHaBP&pid=Api&P=0&h=180',
        'points': [
            'Bundle size improvement: Reduced the bundle size of transactional pages by 66%, resulted in faster page load speeds and greatly enhanced overall site performance by 5%.',
'Reorder: Successfully led the launch of a major feature by developing the backend and frontend to enable the reorder feature on the ”User’s Account” page, allowing users to directly add a previous order from profile page, increasing the revenue by 5 Million in last 6 months.',
'Wishlist: Led the successful development of the backend to enable users to add items to a wishlist on the ”User’s Account” page, enhancing user experience.',
'Recommendation: Integrated the recommendations feature on the ”User’s Account” page, providing personalized product suggestions, improving customer cart conversion rate by 20%'
        ],
        'url': 'https://cimpress.com/',
    },
    {
        'company': 'Cimpress',
        'role': 'Software Engineer',
        'duration': 'Aug 2021 - Jun 2023',
        'logo': 'https://tse3.mm.bing.net/th?id=OIP.FOyST5hIvuz1QmKJ1SgLEgHaBP&pid=Api&P=0&h=180',
        'points': [
            'Migration of legacy: Developed and migrated legacy code into the React framework, resulting in a bug-free launch across 25 countries and a performance improvement of 40%, greatly increased customer conversion rate, by 7% in transactional pages.',
            'Email Validation API: Created an endpoint for validating emails across the organization, ensuring correct and consistent email data throughout the system.',
            'Rebranding: Worked on the complete rebranding of transactional pages to align with the new UI, enhancing the visual appeal and user experience of the site.',
            'Bazaar Voice: Successfully integrated Bazaarvoice for the United States and Canada sites, enabling users to view and add product ratings and reviews, helping build trust and informed purchase decisions.',
            'Test case Improvement: Implemented test cases using Mocha-Chai for Google Analytics, improving test coverage from 30% to 100%, ensuring more code quality.'
        ],
        'url': 'https://cimpress.com/',
    },
    {
        'company': 'MathonGo',
        'role': 'Intern',
        'duration': 'Jan 2021 - Feb 2021',
        'logo': 'https://cdn1.mathongo.com/wp-content/uploads/20190616141133/social_opengraph.png',
        'points': [
            'Website management: Managed and updated the website using the WordPress platform, incorporating user engaging content like past activities to enhance the site’s functionality and user experience.',
            'SERP Improvement: Boosted the search engine results page (SERP) rankings of the website from ranking of 6th to 2nd, increasing organic traffic and visibility, leading to greater user engagement.'
        ],
        'url': 'https://cimpress.com/',
    },
]



export const EMAIL_JS_SERVICE_ID = 'service_7heve2b';
export const EMAIL_JS_TEMPLATE_ID = 'template_mc971fl';
export const EMAIL_JS_PUBLIC_KEY = 'hN7J4UV703q2BtQKW';